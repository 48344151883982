@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';

.horizontalScrollContainer {
  width: 100%;
  min-height: size-rem(720px);
  height: size-rem(700px);
  @include flex-row;
  position: relative;
  top: size-rem(-50px);
  padding-top: size-rem(50px);
  overflow: hidden;
  will-change: transform;

  @include vp--740 {
    height: 55vh;
    min-height: 55vh;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
      width: 0px;
      height: 0px;
    }
  }
}

.__horizontalScroll {
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
    width: 0px;
    height: 0px;
  }
}

.horizontalScrollWrapper {
  position: absolute;
  display: flex;
  flex-direction: row;
  left: 0;
  height: 100%;
  overflow: visible;
  padding-top: size-rem(30px);
  will-change: transform;
  gap: size-rem(20px);
}

.horizontalScrollWrapperMob {
  position: absolute;
  display: flex;
  flex-direction: row;
  left: 0;
  height: 100%;
  overflow: visible;
  padding-top: size-rem(30px);
  will-change: transform;
  gap: size-rem(20px);
  z-index: 1;

  @include vp--740 {
    padding-top: size-rem(62px);
  }
}

.horizontalScrollItem {
  width: size-rem(460px);
  height: size-rem(570px);
  position: relative;
  overflow: hidden;

  @include vp--740 {
    width: size-rem(264px, true);
    height: size-rem(326px, true);
  }
}

.horizontalScrollImg {
  width: 100%;
}

.horizontalScrollImgTitle {
  position: absolute;
  bottom: size-rem(30px);
  left: size-rem(25px);
  width: 100%;
  max-height: size-rem(96px);
  min-height: size-rem(70px);
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: size-rem(32px);
  line-height: 100%;
  color: $clr-light-grey;
  z-index: 19;

  p {
    margin-top: size-rem(0px);
    margin-bottom: size-rem(0px);
    line-height: 110%;
  }

  @include vp--740 {
    font-size: size-rem(17px, true);
    bottom: size-rem(17px, true);
    max-height: size-rem(60px, true);
    min-height: size-rem(50px, true);
    left: size-rem(20px, true);
  }
}

.horizontalScrollImgTitle :nth-child(even) {
  margin-left: size-rem(45px);
}

.horizontalScrollImgGradient {
  position: absolute;
  width: size-rem(682px);
  height: size-rem(245px);
  top: size-rem(326.83px);

  @include vp--740 {
    left: size-rem(-60.52px, true);
    top: size-rem(206.55px, true);
    width: size-rem(356px, true);
    height: size-rem(128px, true);
  }
}
