@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/flexbox.scss';
@import '../../../assets/scss/mixins/responsive.scss';

.fourthScreenContainer {
  width: 100%;
  min-height: size-rem(1000px);
  position: relative;
  height: fit-content;
  overflow: hidden;
  padding-top: size-rem(150px);
  @include vp--740 {
    height: size-rem(1024px, true);
  }
}

.fourthScreenWrapper {
  position: relative;
  width: 100%;
  height: size-rem(600px);
  @include flex-row;
  justify-content: center;
  @include vp--740 {
    align-items: center;
    justify-content: center;
    height: size-rem(500px, true);
  }
}

.fourthScreenTextBox {
  @include flex-column;
  width: 30%;
  align-items: center;
  @include vp--740 {
    width: 100%;
    height: 100%;
  }
}

.fourthScreenTitle {
  width: size-rem(632px);
  font-family: $main-font-family;
  font-weight: 300;
  font-size: size-rem(85px);
  line-height: 95%;
  text-align: center;
  letter-spacing: -0.02em;
  @include vp--740 {
    width: size-rem(320px, true);
    font-size: size-rem(40px, true);
    margin-top: size-rem(54px, true);
  }
}

.fourthScreenDescription {
  width: size-rem(468px);
  margin-top: size-rem(20px);
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: size-rem(18px);
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.02em;
  color: $clr-dark-grey;
  @include vp--740 {
    width: size-rem(350px, true);
    font-size: size-rem(16px, true);
    margin-top: size-rem(30px, true);
    z-index: 3;
  }
}

.fourthScreenImgOne {
  overflow: hidden;
  position: relative;
  top: size-rem(-100px);
  left: size-rem(-20px);
  width: size-rem(409px);
}

.fourthScreenImgTwo {
  overflow: hidden;
  position: relative;
  right: size-rem(-20px);
  top: size-rem(-100px);
  width: size-rem(409px);
}

.fourthScreenSvg_two {
  position: absolute;
  width: 105.23673vw;
  top: -78vw;
  left: -3vw;
  @include vp--740 {
    top: -48vw;
  }
}
.fourthScreenSvg_one {
  position: absolute;
  width: 59.23673vw;
  left: -3.74453vw;
  top: -37vw;
}
